import React from "react";

interface IconProps extends HTMLAttributes<SVGElement> {
    width?: number;
    height?: number;
  }
function ZapierIcon({
    width = 60,
    height = 63,
    ...restProps
  }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...restProps}
      viewBox="0 0 256 256"
    >
      <path
        fill="#FF4A00"
        d="M128.08 0c7.231.013 14.343.624 21.256 1.78V76.3l52.831-52.696a128.425 128.425 0 0116.34 13.789 128.468 128.468 0 0113.84 16.312L179.513 106.4h74.715A127.58 127.58 0 01256 127.587v.173c0 7.226-.613 14.306-1.772 21.2H179.5l52.847 52.682a129.615 129.615 0 01-13.824 16.312h-.015a128.254 128.254 0 01-16.326 13.789l-52.846-52.696v74.52a130.321 130.321 0 01-21.243 1.781h-.186a130.26 130.26 0 01-21.23-1.78v-74.52l-52.831 52.695a128.401 128.401 0 01-30.18-30.1L76.5 148.96H1.785A126.984 126.984 0 010 127.72v-.371c.012-1.875.135-4.166.311-6.536l.055-.713c.522-6.671 1.419-13.7 1.419-13.7H76.5L23.666 53.705a126.265 126.265 0 0113.81-16.286l.026-.026a127.746 127.746 0 0116.344-13.789L106.677 76.3V1.78A130.278 130.278 0 01127.933 0h.147zm-.013 95.76h-.122c-9.509 0-18.616 1.74-27.034 4.902a76.662 76.662 0 00-4.915 26.952v.12a76.383 76.383 0 004.927 26.951 76.608 76.608 0 0027.022 4.902h.122c9.51 0 18.617-1.74 27.022-4.902a76.146 76.146 0 004.915-26.952v-.12c0-9.484-1.747-18.57-4.915-26.951a76.614 76.614 0 00-27.022-4.902z"
      ></path>
    </svg>
  );
}

export default ZapierIcon;
